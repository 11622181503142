import { defineStore } from 'pinia'
import type { Review } from '~/models/Review'
import type { Quote } from '~/models/Quote'

export const useModalStore = defineStore('modal', {
  state: () => ({
    showErrorModal: false,
    errorMessage: '',
    showSuccessModal: false,
    successMessage: '',
    showExclusionModal: false,
    showUserNotLoggedModal: false,
    showSpaceReviewModal: false,
    showEditReviewModal: false,
    showEditQuotationModal: false,
    showQuotationChatModal: false,
    showReviewModal: false,
    nextRouteOnSuccess: '',
    nextRouteOnError: '',
    review: {},
    quotation: {},
    quotationReplies: [],
    callbackFunction: {},
  }),
  getters: {
    getShowErrorModal: (state) => {
      return state.showErrorModal
    },
    getErrorMessage: (state) => {
      return state.errorMessage
    },
    getShowSuccessModal: (state) => {
      return state.showSuccessModal
    },
    getSuccessMessage: (state) => {
      return state.successMessage
    },
    getShowExclusionModal: (state) => {
      return state.showExclusionModal
    },
    getShowUserNotLoggedModal: (state) => {
      return state.showUserNotLoggedModal
    },
    getShowSpaceReviewModal: (state) => {
      return state.showSpaceReviewModal
    },
    getShowEditReviewModal: (state) => {
      return state.showEditReviewModal
    },
    getShowEditQuotationModal: (state) => {
      return state.showEditQuotationModal
    },
    getShowQuotationChatModal: (state) => {
      return state.showQuotationChatModal
    },
    getShowReviewModal: (state) => {
      return state.showReviewModal
    },
    getNextRouteOnSuccess: (state) => {
      return state.nextRouteOnSuccess
    },
    getNextRouteOnError: (state) => {
      return state.nextRouteOnError
    },
    getReview: (state) => {
      return state.review
    },
    getQuotation: (state) => {
      return state.quotation
    },
    getQuotationReplies: (state) => {
      return state.quotationReplies
    },
  },
  actions: {
    setShowErrorModal(value: boolean) {
      this.showErrorModal = value
    },
    setShowSuccessModal(value: boolean) {
      this.showSuccessModal = value
    },
    setShowExclusionModal(value: boolean) {
      this.showExclusionModal = value
    },
    setShowUserNotLoggedModal(value: boolean) {
      this.showUserNotLoggedModal = value
    },
    setShowSpaceReviewModal(value: boolean) {
      this.showSpaceReviewModal = value
    },
    setShowEditReviewModal(value: boolean) {
      this.showEditReviewModal = value
    },
    setShowEditQuotationModal(value: boolean) {
      this.showEditQuotationModal = value
    },
    setShowQuotationChatModal(value: boolean) {
      this.showQuotationChatModal = value
    },
    setShowReviewModal(value: boolean) {
      this.showReviewModal = value
    },
    setErrorMessage(value: string) {
      this.errorMessage = value
    },
    setSuccessMessage(value: string) {
      this.successMessage = value
    },
    setNextRouteOnSuccess(value: string) {
      this.nextRouteOnSuccess = value
    },
    setNextRouteOnError(value: string) {
      this.nextRouteOnError = value
    },
    setReview(value: Partial<Review>) {
      this.review = value
    },
    setQuotation(value: Partial<Quote>) {
      this.quotation = value
    },
    setQuotationReplies(value: any) {
      this.quotationReplies = value
    },
  },
})
